import { Fragment, FunctionalComponent, h } from "preact";
import Router, { route, Route, RouterOnChangeArgs } from "preact-router";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setAliasMode,
  setPortalAlias,
  setPortalId,
  setWorkspaceAlias,
  setWorkspaceId,
} from "../../redux/slices/aliasSlice";
import {
  setMode,
  setPreview,
  setNarrowFactor,
} from "../../redux/slices/appearanceSlice";

import { setAzureSasToken } from "../../redux/slices/authSlice";

import { get_DASHBOARD_URI_BASE } from "../../configs/env";
import { Utils,checkPublicUserId, setTheme } from "../../utils";

import axios from "axios";

import Home from "../../routes/home";
import Search from "../../routes/search";
import Pdf from "../../routes/pdf";
import Video from "../../routes/video";
import Sheet from "../../routes/sheet";
import Notfound from "../../routes/notfound";
import CustomError from "../../routes/customError";
import Login from "../../routes/login";
import { setClientIp,setUserId, setUserIdRan } from "../../redux/slices/globalsSlice";
import Chatbot from "../../routes/chatbot";
export interface RouterProps {
  workspaceId: string;
  kappId: string;
  portalId: string;
  workspaceAlias: string;
  portalAlias: string;

  // editor
  preview?: string;

  // search
  q: string;
  // pdf / video
  guid: string;

  // pdf
  docId: string; // docGuid
  page: string;

  // video
  docGuid: string;

  // widget
  mode: "widget";

  // narrowfactor
  narrowfactor: string;

  sasTokenPreview?: string;
  chatbotFlag?: boolean;
}
const AppRouter: FunctionalComponent = () => {

  const appearance = useAppSelector((state) => state.appearance);
  const globals = useAppSelector((state) => state.globals);
  const alias = useAppSelector((state) => state.alias);
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  /**
   * error 400
   * @param routerProps
   */
  const route400 = (routerProps: RouterProps) => {
    console.warn(
      `Something wrong with routerProps: ${JSON.stringify(
        routerProps,
        null,
        2
      )}`
    );
    route(`/error/${400}/errorMessage/${"Bad URL."}`);
  };

  const route422 = (errorMessage: string) => {
    route(`/error/${422}/errorMessage/${errorMessage}`);
  };

  const isSasTokenExpired = (timestamp: number) => {
    const now = new Date().getTime();
    const diff = now - timestamp;
    const minutes = Math.floor(diff / 1000 / 60);
    return minutes > 30;
  };

  /**
   * on route change
   * @param args
   * @returns
   */
  const onChange = async (args: RouterOnChangeArgs) => {
    try {
      console.log("onchange", args);
      const matches = (args.current.props as any)?.matches;
      if (args.path?.includes("/login")) return;
      if (matches) {
        const routerProps = {
          workspaceId: matches?.workspaceId,
          kappId: matches?.kappId,
          portalId: matches?.portalId,
          workspaceAlias: (args.current.props as any)?.matches?.workspaceAlias,
          portalAlias: matches?.portalAlias,
          preview: matches?.preview,
          q: matches?.q,
          guid: matches?.guid,
          docId: matches?.docId,
          page: matches?.page,
          docGuid: matches?.docGuid,
          mode: matches?.mode,
          narrowfactor: matches?.narrowfactor,
          sasTokenPreview: matches?.sasTokenPreview,
          chatbotFlag: args.path?.includes("/chatbot"),
        } as RouterProps;
        if (routerProps.preview === "preview") {
          dispatch(setPreview(true));
          if (routerProps.sasTokenPreview) {
            dispatch(
              setAzureSasToken(decodeURIComponent(routerProps.sasTokenPreview))
            );
          }
        }

        if(appearance.loaded && !appearance.isPrivate && !globals.userIdRan){
          const userID = checkPublicUserId();
          dispatch(setUserId(userID));
    
          //to make user we dont run the useEffect more than once just when appearance.loaded === true 
          dispatch(setUserIdRan(true));
        }

        if (!appearance.narrowfactor && routerProps.narrowfactor == "1") {
          dispatch(setNarrowFactor(1));
        }

        if (routerProps.mode && routerProps.mode !== appearance.mode)
          dispatch(setMode(routerProps.mode));

        if (!globals.clientIp) {
          try {
            const _clientIp = await axios.get(
              "https://api.ipify.org?format=json"
            );
            if (_clientIp?.data?.ip) {
              dispatch(setClientIp(_clientIp.data.ip));
            }
          } catch (error) {
            console.log(error);
          }
        }

        if (
          routerProps.workspaceAlias &&
          routerProps.portalAlias &&
          !alias.workspaceId &&
          !alias.portalId
        ) {
          const _aliasResponce = await axios(
            `${get_DASHBOARD_URI_BASE()}/alias/${routerProps.workspaceAlias}/${
              routerProps.portalAlias
            }`
          );
          const aliasResponse = _aliasResponce?.data?.data;

          if (aliasResponse?.workspaceId && aliasResponse?.portalId) {
            dispatch(setWorkspaceId(aliasResponse.workspaceId));
            dispatch(setPortalId(aliasResponse.portalId));
            dispatch(setWorkspaceAlias(routerProps.workspaceAlias));
            dispatch(setPortalAlias(routerProps.portalAlias));
            dispatch(setAliasMode(aliasResponse.mode))

            if (aliasResponse.theme) {
              setTheme(
                aliasResponse.theme,
                dispatch,
                aliasResponse.workspaceId
              );
            }
            if ((aliasResponse.mode ==="chatbot" && aliasResponse.chatTheme.isPrivate && routerProps.mode !== "widget") ||  aliasResponse.theme.isPrivate) {
              if (routerProps.preview === "preview") {
                dispatch(setPreview(true));
                return;
              }

              if (!auth.korraUser && !auth.auth0User) {
                Utils.routeToLogin({
                  workspaceAlias: routerProps.workspaceAlias,
                  portalAlias: routerProps.portalAlias,
                });
              }
            }
            if(!aliasResponse.theme.isPrivate && !globals.userIdRan){
              const userID = checkPublicUserId();
              dispatch(setUserId(userID));
        
              //to make user we dont run the useEffect more than once just when appearance.loaded === true 
              dispatch(setUserIdRan(true));
            }
          } else {
            route422("It is alias but couldn't get alias response");
          }
        } else if (
          routerProps.workspaceId &&
          (routerProps.portalId || routerProps.kappId)
        ) {
          dispatch(setWorkspaceId(routerProps.workspaceId));
          if (routerProps.portalId) {
            dispatch(setPortalId(routerProps.portalId));

            const theme = await Utils.getTheme(
              routerProps.workspaceId,
              routerProps.portalId,
              !routerProps.mode
            );
            if (theme) {
              setTheme(theme, dispatch, routerProps.workspaceId);
            }
            if (theme?.isPrivate) {
              if (routerProps.preview === "preview") {
                dispatch(setPreview(true));
                return;
              }
              if (!auth.korraUser && !auth.auth0User) {
                Utils.routeToLogin(null);
              }
            }
            if(!theme?.isPrivate && !globals.userIdRan){
              const userID = checkPublicUserId();
              dispatch(setUserId(userID));
        
              //to make user we dont run the useEffect more than once just when appearance.loaded === true 
              dispatch(setUserIdRan(true));
            }
            
          } else if (routerProps.kappId) {
            // dispatch(setKappId(routerProps.kappId));
            // no need theme, no need alias, all is ok, it is kapp
          } else {
            route400(routerProps);
          }
        } else {
          if (
            appearance.isPrivate &&
            (!alias.workspaceAlias || !alias.workspaceId)
          ) {
            if (routerProps.preview === "preview") {
              dispatch(setPreview(true));
              return;
            }
            route400(routerProps);
          }
        }
      } else {
        route422("No matches at URL params");
      }
    } catch (error: any) {
      console.error(error);
      route422("Something wrong: " + error?.message || "");
    }
  };

  console.log("rendering router")
  return (
    <Fragment>
      <Router onChange={onChange}>
        <Route path="/workspace/:workspaceId/kapp/:kappId" component={Home} />
        <Route
          path="/workspace/:workspaceId/kapp/:kappId/home"
          component={Home}
        />
        <Route
          path="/workspace/:workspaceId/portal/:portalId/chatbot"
          component={Chatbot}
        />
        <Route
          path="/workspace/:workspaceId/portal/:portalId"
          component={Home}
        />
        <Route
          path="/workspace/:workspaceId/portal/:portalId/home"
          component={Home}
        />
        <Route
          path="/workspace/:workspaceId/portal/:portalId/:preview/home"
          component={Home}
        />
        <Route
          path="/workspace/:workspaceId/kapp/:kappId/search"
          component={Search}
        />
        <Route
          path="/workspace/:workspaceId/portal/:portalId/search"
          component={Search}
        />
        <Route
          path="/workspace/:workspaceId/kapp/:kappId/video/docGuid/:docGuid/guid/:guid"
          component={Video}
        />
        <Route
          path="/workspace/:workspaceId/portal/:portalId/kapp/:kappId/video/docGuid/:docGuid/guid/:guid"
          component={Video}
        />
        <Route
          path="/workspace/:workspaceId/kapp/:kappId/pdf/docGuid/:docId/page/:page/guid/:guid"
          component={Pdf}
        />
        <Route
          path="/workspace/:workspaceId/portal/:portalId/kapp/:kappId/pdf/docGuid/:docId/page/:page/guid/:guid"
          component={Pdf}
        />

        <Route
          path="/workspace/:workspaceId/kapp/:kappId/sheet/docGuid/:docId/guid/:guid"
          component={Sheet}
        />
        <Route
          path="/workspace/:workspaceId/portal/:portalId/kapp/:kappId/sheet/docGuid/:docId/guid/:guid"
          component={Sheet}
        />

        <Route path="/company/:workspaceId/kapp/:kappId" component={Home} />
        <Route
          path="/company/:workspaceId/kapp/:kappId/home"
          component={Home}
        />
        <Route path="/company/:workspaceId/portal/:portalId" component={Home} />
        <Route
          path="/company/:workspaceId/portal/:portalId/home"
          component={Home}
        />
        <Route
          path="/company/:workspaceId/portal/:portalId/:preview/home"
          component={Home}
        />
        <Route
          path="/company/:workspaceId/kapp/:kappId/search"
          component={Search}
        />
        <Route
          path="/company/:workspaceId/portal/:portalId/search"
          component={Search}
        />
        <Route
          path="/company/:workspaceId/kapp/:kappId/video/docGuid/:docGuid/guid/:guid"
          component={Video}
        />
        <Route
          path="/company/:workspaceId/portal/:portalId/kapp/:kappId/video/docGuid/:docGuid/guid/:guid"
          component={Video}
        />
        <Route
          path="/company/:workspaceId/kapp/:kappId/pdf/docGuid/:docId/page/:page/guid/:guid"
          component={Pdf}
        />
        <Route
          path="/company/:workspaceId/portal/:portalId/kapp/:kappId/pdf/docGuid/:docId/page/:page/guid/:guid"
          component={Pdf}
        />
        <Route
          path="/company/:workspaceId/kapp/:kappId/sheet/docGuid/:docId/guid/:guid"
          component={Sheet}
        />
        <Route
          path="/company/:workspaceId/portal/:portalId/kapp/:kappId/sheet/docGuid/:docId/guid/:guid"
          component={Sheet}
        />

        {/*  */}

        <Route path="/:workspaceAlias/:portalAlias" component={Home} />
        <Route path="/:workspaceAlias/:portalAlias/home" component={Home} />
        <Route path="/:workspaceAlias/:portalAlias/search" component={Search} />
        <Route path="/:workspaceAlias/:portalAlias/login" component={Login} />
        <Route
          path="/:workspaceAlias/:portalAlias/chatbot"
          component={Chatbot}
        />
        <Route
          path="/:workspaceAlias/:portalAlias/chatbot?mode=widget"
          component={Chatbot}
        />
        <Route
          path="/:workspaceAlias/:portalAlias/kapp/:kappId/video/docGuid/:docGuid/guid/:guid"
          component={Video}
        />
        <Route
          path="/:workspaceAlias/:portalAlias/kapp/:kappId/video/docGuid/:docGuid/guid/:guid/chatbotFlag/:chatbotFlag"
          component={Video}
        />
        <Route
          path="/:workspaceAlias/:portalAlias/kapp/:kappId/pdf/docGuid/:docId/page/:page/guid/:guid/chatbotFlag/:chatbotFlag"
          component={Pdf}
        />
        <Route
          path="/:workspaceAlias/:portalAlias/kapp/:kappId/pdf/docGuid/:docId/page/:page/guid/:guid"
          component={Pdf}
        />
        <Route
          path="/:workspaceAlias/:portalAlias/kapp/:kappId/sheet/docGuid/:docId/guid/:guid"
          component={Sheet}
        />
        <Route
          path="/:workspaceAlias/:portalAlias/kapp/:kappId/sheet/docGuid/:docId/guid/:guid/chatbotFlag/:chatbotFlag"
          component={Sheet}
        />

        {/*  */}

        <Route
          path="/error/:statusCode/errorMessage/:errorMessage"
          component={CustomError}
        />

        <Notfound default />

        {/*  */}
        <Route
          path="/:workspaceAlias/:portalAlias/login/redirect/:redirect"
          component={Login}
        />
        <Route path="/login/redirect/:redirect" component={Login} />
        <Route path="/login" component={Login} />

        {/* private */}
      </Router>
    </Fragment>
  );
};

export default AppRouter;
